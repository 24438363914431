// extracted by mini-css-extract-plugin
export var header = "styles-module--header--HqvIP";
export var image = "styles-module--image--zQD7W";
export var license = "styles-module--license--+1cKe";
export var link = "styles-module--link--S+JO0";
export var list = "styles-module--list--0Ua6O";
export var logo = "styles-module--logo--Md71B";
export var mainHeader = "styles-module--mainHeader--SwooW";
export var page = "styles-module--page--zUr96";
export var section = "styles-module--section--f3H76";
export var texts = "styles-module--texts--U2Ifm";